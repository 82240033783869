<template>
  <v-app>
    <div
      class="pages-wrapp"
      style="position: relative; width: 100%; height: 100%"
      :style="dataFullLoading ? 'visibility: visible;' : 'visibility: hidden;'"
    >
      <template v-for="(selectedPage, pindex) in pages">
        <div
          v-if="(selected && selected.pagenumber === selectedPage.pagenumber) || showAll"
          :id="'page-' + selectedPage.pagenumber + pindex"
          :key="'page-' + selectedPage.pagenumber + pindex"
          class="page-layout"
          :style="`width: ${plwidth}px; height: ${plheight}px;`"
        >
          <div
            class="page-inner-layout"
            :style="pageStyle(selectedPage)"
            style="background-size: contain"
          >
            <div
              class="page-header"
            />
            <div
              v-for="(rect, index) in selectedPage.pagepattern.data"
              :key="'reckt-' + index"
              :style="`position:absolute; height: ${rect.height}px; width: ${rect.width}px; top: ${rect.top}px; left: ${rect.left}px`"
            >
              <table-view
                v-if="rect.mode==='table'"
                :print="true"
                :index="1"
                :table="rect.tableData"
                :can-edit="false"
                :show-table-title="rect.showTableTitle"
                :presentation="true"
                :vcentred="rect.vcentred"
              />
              <table-chart
                v-else-if="rect.mode==='chart' && rect.tableData.data"
                :right-admin="false"
                :print-mode="true"
                :table-data="rect.tableData"
                :hard-assignments-chart="parseInt(rect.chartID, 10)"
                :inn-height="rect.height"
              />
              <div
                v-else-if="rect.mode==='text'"
                style="width: 100%; height: 100%; white-space: pre-wrap; display: flex;"
                :style="textStyle(rect)"
                v-text="rect.text"
              />
              <div
                v-else-if="rect.mode==='pagenumb'"
                style="width: 100%; height: 100%; white-space: pre-wrap;"
                :style="textStyle(rect)"
                @dblclick="editText(rect)"
                v-text="selectedPage.pagenumber+1"
              />
            </div>
          </div>
        </div>
      </template>
    </div>
    <data-loading />
  </v-app>
</template>

<script>
  // import tableView from './tableCanvas'
  import tableView from './tableVfViewOnClass.vue'
  import tableChart from './tableChart'
  import { api as fullscreen } from 'vue-fullscreen'
  import dataLoading from '../components/dataLoading'
  import fotPage from './fotPage.json'

  export default {
    name: 'ReportViewer',
    components: {
      tableView,
      tableChart,
      dataLoading,
    },
    data () {
      return {
        dataFullLoading: false,
        showPDFOverlay: false,
        showAll: false,
        fileDU: null,
        file: null,
        makeCopy: false,
        editable: true,
        mainDialog: false,
        pageDialog: false,
        editTextDialog: false,
        fillDataDialog: false,
        tableConfigDialog: false,
        addTableDialog: false,
        tempPage: null,
        showOverlay: false,
        plversionid: null,
        plperiodid: null,
        plPeriods: null,
        plVersions: null,
        prTemplate: null,
        planningTables: {},
        versions: {},
        planningReportsGets: [],
        barBtn: [
          {
            icon: 'mdi-file-pdf-box',
            action: () => this.pageToPDF(1),
            color: 'primary',
            text: 'Сохранить в PDF',
          },
          {
            icon: 'mdi-overscan',
            action: () => this.slideMode(1),
            text: 'Режим презентации',
            color: 'primary',
          },
          {
            icon: 'mdi-plus',
            action: () => this.zoomIn(),
            text: 'Увеличить масштаб',
            color: 'primary',
          },
          {
            icon: 'mdi-minus',
            action: () => this.zoomOut(),
            text: 'Уменьшить масштаб',
            color: 'primary',
          },
        ],
        tableConfig: null,
        plreportid: null,
        activeArr: [],
        templateId: null,
        rectMode: 'table',
        pages: [],
        pStylesDialog: true,
        selected: null,
        initPages: null,
        fontSize: this.genSequence(13, 36),
        fontFamily: [
          'Arial, sans-serif',
          'Verdana, sans-serif',
          'Helvetica, sans-serif',
          'Tahoma, sans-serif',
          'Trebuchet MS, sans-serif',
          'Times New Roman, serif',
          'Georgia, serif',
          'Garamond, serif',
          'Courier New, monospace',
          'Brush Script MT, cursive',
        ],
        plwidth: 1754,
        plheight: 1240,
        listWidth: 0,
        listHeight: 0,
        windowHeight: 0,
        dragged: false,
        width: 0,
        height: 0,
        top: 0,
        left: 0,
        mouseOffsetX: 0,
        mouseOffsetY: 0,
        tableData: null,
        fullscreen: false,
        scale: 1,
        zoomStep: 0.03,
        rectInput: null,
        tablesTemplatesList: null,
        versionTables: null,
        pagesToDelete: [],
        plpresentation: null,
        hidePages: [],
        hidedata: null,
      }
    },
    computed: {
      // eslint-disable-next-line vue/return-in-computed-property
      haveChanges () {
        return JSON.stringify(this.pages) !== JSON.stringify(this.initPages)
      },
      haveAdvancedPage () {
        if (this.pages.length > 0) {
          const RAmatch = this.pages.filter(x => !!x.flgrightsadvanced)
          return RAmatch.length > 0
        } else {
          return false
        }
      },
      createNewPrTp () {
        return this.templateSelected && this.selectedArea && this.chosenPlTemplate && this.templateSelected.length === 0
      },
      dataLoading () {
        return this.$store.getters.dataLoading
      },
      tableStyles () {
        return this.$store.getters.tableStyles
      },
    },
    watch: {
      fullscreen () {
        this.onResize()
      },
    },
    beforeDestroy () {
      document.removeEventListener('keyup', this.keysActions)
    },
    mounted () {
      if (this.tableStyles === null) {
        this.$store.dispatch('getTableStyles')
      }
      const plversion = this.$route.query.plversion
      let pages = this.$route.query.pages
      const hidedata = this.$route.query.hidedata
      const dataArr = this.$route.query.tArray
      if (hidedata === '1') pages = 'all'
      console.log('plversion', plversion)
      if (plversion && plversion === '7777') {
        this.$store.dispatch('setDataLoading', true)
        // const tArray = [4823, 4851, 4963, 4994, 5022, 5080, 5137, 5689, 5789, 5820, 6428, 6637, 6853, 7030, 7059, 7435, 8254, 9128, 9301, 9377, 9407]
        // const tArray = [27270, 28250, 28280, 28379, 28417, 28468, 28504, 28610, 28711, 28753, 28902, 29149, 29371, 29399, 29791, 29819, 29871, 30041, 30076, 30461, 32000, 32593]
        let tArray = null
        if (dataArr) {
          tArray = JSON.parse(dataArr)
        } else {
          tArray = [27270, 28250, 28280, 28379, 28417, 28468, 28504, 28610, 28711, 28753, 28902, 29149, 29371, 29399, 29871, 30041, 30076, 32000, 32593]
        }
        const fotPages = []
        tArray.forEach((t, i) => {
          const pTemlate = JSON.parse(JSON.stringify(fotPage))
          pTemlate.id = i
          pTemlate.pagenumber = i + 1
          pTemlate.pagepattern.data[2].tableData.info.id = t
          fotPages.push(pTemlate)
        })
        console.log(fotPages)
        this.mainDialog = false
        this.pages = fotPages
        this.initPages = JSON.parse(JSON.stringify(this.pages))
        this.pages.forEach((page) => {
          console.log('page', page)
          page.pagepattern.data.forEach((rect) => {
            if (rect.mode === 'table') {
              this.$store.dispatch('getTableData', rect.tableData.info.id).then((resp) => {
                if (rect.maxRows) {
                  console.log('maxRows')
                  const tableData = []
                  rect.tableData = JSON.parse(JSON.stringify(resp))
                  page.pagepattern.data[1].text = 'Фонд оплаты труда. ' + resp.info.areatitle
                  while (rect.tableData.data.length > rect.maxRows) {
                    const trimmed = this.trimRows(rect.maxRows, rect.tableData)
                    tableData.push(trimmed)
                  }
                  console.log('-----', rect.tableData)
                  tableData.push(JSON.parse(JSON.stringify(rect.tableData)))
                  console.log('-----', tableData)
                  tableData.forEach((ttable, index) => {
                    console.log('ttable', ttable)
                    if (index === 0) {
                      rect.tableData.data = ttable.data
                      rect.tableData.info.title = resp.info.title
                    } else {
                      const newPage = {
                        pagenumber: page.pagenumber + index,
                        pagetitle: page.pagetitle + '-' + index,
                        flgrightsadvanced: page.flgrightsadvanced,
                        helptext: page.helptext,
                        pagepattern: JSON.parse(JSON.stringify(page.pagepattern)),
                        reportid: page.reportid,
                        template: page.template,
                        title: page.title,
                      }
                      newPage.pagepattern.data.forEach((x) => {
                        if (x.mode === 'table' && x.tableData.info.id === ttable.info.id) {
                          x.tableData = ttable
                        }
                      })
                      this.pages.forEach((p) => {
                        if (p.pagenumber >= page.pagenumber + 1) {
                          p.pagenumber = p.pagenumber + 1
                        }
                      })
                      rect.tableData.info = resp.info
                      console.log('pagenumber', page.pagenumber)
                      console.log('index', index)
                      console.log('newPage', newPage)
                      this.pages.splice(page.pagenumber + index, 0, newPage)
                    }
                  })
                } else {
                  rect.tableData = resp
                  page.pagepattern.data[1].text = 'Фонд оплаты труда. ' + resp.info.areatitle
                }
              })
            }
          })
        })
        setTimeout(() => {
          this.pages.sort((a, b) => {
            return a.pagenumber - b.pagenumber
          })
          this.$store.dispatch('setDataLoading', false)
          this.showAll = true
          this.dataFullLoading = true
        }, 1000)
        // this.fillAllTables([resp[0].reportid, plversion])
      } else if (plversion) {
        this.$store.dispatch('setDataLoading', true)
        this.$store.dispatch('getPlanningPr', plversion).then((resp) => {
          console.log('resp', resp)
          this.mainDialog = false
          resp.forEach((page) => {
            if (page.pagepattern) {
              page.pagepattern = JSON.parse(page.pagepattern.replace(/'/g, '"'))
            }
            if (page.template) {
              page.template = JSON.parse(page.template.replace(/'/g, '"'))
            }
          })
          if (hidedata === '1') {
            this.hidedata = 1
          }
          if (pages === 'all') {
            this.pages = resp
          } else {
            this.pages = resp.filter(x => !x.flgrightsadvanced)
          }
          this.pages.sort((a, b) => {
            return a.pagenumber - b.pagenumber
          })
          this.initPages = JSON.parse(JSON.stringify(this.pages))
          this.fillAllTables([resp[0].reportid, plversion])
        })
      }
      if (this.tableStyles === null) {
        this.$store.dispatch('getTableStyles')
      }
      if (!this.$store.getters.cssLoad) {
        this.$store.dispatch('getStyle').then((resp) => {
          const css = resp
          const style = document.createElement('style')
          document.head.appendChild(style) // or something of the likes
          style.type = 'text/css'
          if (style.styleSheet) {
            // This is required for IE8 and below.
            style.styleSheet.cssText = css
          } else {
            style.appendChild(document.createTextNode(css))
          }
          this.$store.dispatch('setCssLoad', true)
        })
      }
      this.windowHeight = window.innerHeight
    },
    methods: {
      keysActions (evnt) {
        console.log(evnt)
        let direct = 0
        if (evnt.key === 'ArrowLeft' || evnt.key === 'ArrowUp') {
          direct = -1
        } else if (evnt.key === 'ArrowRight' || evnt.key === 'ArrowDown') {
          direct = 1
        }
        if (direct !== 0) this.flipPage(direct)
      },
      getPrPages (payload) {
        this.mainDialog = false
        this.$store.dispatch('setDataLoading', true)
        this.plpresentation = payload.present
        this.plreportid = payload.cr
        this.$store.dispatch('getPlanningVersionsList', { chosenRep: payload.cr, areaid: payload.area }).then((resp) => {
          this.plVersions = resp
          this.plPeriods = Object.keys(resp)
        })
        this.$store.dispatch('getPrData', payload.plt).then((prdata) => {
          this.$store.dispatch('getTablesTempsList', payload.cr).then((resp) => {
            this.tablesTemplatesList = resp
            this.prTemplate = payload.plt
            prdata.forEach((page) => {
              page.pagepattern = JSON.parse(page.pagepattern.replace(/'/g, '"'))
            })
            this.pages = prdata
            this.initPages = JSON.parse(JSON.stringify(this.pages))
            // this.$store.dispatch('setDataLoading', false)
          }).catch((e) => {
            console.log('Error:', e)
            this.$store.dispatch('setDataLoading', false)
            this.mainDialog = true
          })
        }).catch((e) => {
          this.$store.dispatch('setDataLoading', false)
          this.mainDialog = true
        })
      },
      getBase64 (file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = function () {
            resolve(reader.result)
          }
          reader.onerror = function (error) {
            reject(error)
          }
        })
      },
      uploadImage (event) {
        this.getBase64(event).then((resp) => {
          this.fileDU = resp
        })
      },
      showPageDialog (pageIndex = null) {
        if (pageIndex === null) {
          this.tempPage = {
            pageid: null,
            plprid: this.prTemplate,
            pagetitle: 'page-' + (this.pages.length + 1),
            pagenumber: this.pages.length,
            pagepattern: {
              appearance: {},
              data: [],
            },
          }
        } else {
          this.tempPage = JSON.parse(JSON.stringify(this.pages[pageIndex]))
        }
        this.pageDialog = true
      },
      trimRows (maxRows, tableData) {
        const table = JSON.parse(JSON.stringify(tableData))
        table.data = tableData.data.splice(0, maxRows)
        return table
      },
      pageStyle (page) {
        if (page && page.template && page.template.backgroundImage) return `background-image: url('${page.template.backgroundImage}'); background-size: cover;`
        return ''
      },
      showOptions (rect) {
        this.tableConfigDialog = true
        this.tableConfig = rect
      },
      fillAllTables ([plreportid, plversionid]) {
        this.fillDataDialog = false
        this.$store.dispatch('setDataLoading', true)
        let versionTables = null
        this.$store.dispatch('getRowPlanningDataList', { plreportid: plreportid, plversionid: plversionid }).then(resp => {
          console.log('tablesData1', resp)
          versionTables = resp
          const hidePages = this.hidePages
          const tablesToLoad = []
          // const tablesIDToLoad = []
          const tablesDataLoad = {}
          const getTableData = (id) => this.$store.dispatch('getTableData', { id: id, flg: this.hidedata })
          const versTfor = JSON.parse(JSON.stringify(versionTables))
          this.pages.forEach((page) => {
            page.pagepattern.data.forEach((rect) => {
              if ((rect.mode === 'table' || rect.mode === 'chart') && rect.tableData && rect.tableData.info && rect.tableData.info.id && versionTables) {
                const temptableList = versTfor.filter(x => x.pltableid === rect.tableData.info.id) || { id: null }
                temptableList.forEach((temptable) => {
                  if (temptable.id) {
                    tablesToLoad.push(getTableData(temptable.id))
                    // tablesIDToLoad.push(temptable.id)
                  }
                })
              }
            })
          })
          console.log('tables', tablesDataLoad)
          // this.$store.dispatch('getTablesData', { tables: tablesIDToLoad, flg: this.hidedata })
          Promise.allSettled(tablesToLoad).then((tables) => {
            tables.forEach((table) => {
              tablesDataLoad[table.value.info.id] = table.value
            })
            console.log('this.pages', JSON.parse(JSON.stringify(this.pages)))
            const newPages = []
            const delPages = []
            let pageNumber = -1
            let currentpage = null
            this.pages.forEach((page, pageIndex) => {
              pageNumber++
              page.pagenumber = pageNumber
              page.pagepattern.data.forEach((rect, rectIndex) => {
                if ((rect.mode === 'table' || rect.mode === 'chart') && rect.tableData && rect.tableData.info && rect.tableData.info.id && versionTables) {
                  const originTableTop = rect.top
                  const totalHeight = rect.height
                  const oneTableHeight = totalHeight / rect.maxTables
                  const temptableList = versionTables.filter(x => x.pltableid === rect.tableData.info.id) || { id: null }
                  let count = 0
                  let tnumber = 0
                  const pagePatData = JSON.parse(JSON.stringify(page.pagepattern.data))
                  temptableList.forEach((temptable, indx) => {
                    if (temptable.id) {
                      const resp = tablesDataLoad[temptable.id]
                      if (temptableList.length > 1 && !rect.maxRows && !rect.maxTables) rect.maxTables = 1
                      if (rect.maxRows) {
                        const tableData = []
                        rect.tableData = JSON.parse(JSON.stringify(resp))
                        const rowsCount = rect.maxRowsTransfer || rect.maxRows
                        while (rect.tableData.data.length > rowsCount && rect.tableData.data.length > rect.maxRows) {
                          const trimmed = this.trimRows(rect.maxRows, rect.tableData)
                          tableData.push(trimmed)
                        }
                        tableData.push(JSON.parse(JSON.stringify(rect.tableData)))
                        tableData.forEach((ttable, index) => {
                          if (index === 0 && tnumber === 0) {
                            rect.tableData = JSON.parse(JSON.stringify(ttable))
                            newPages.push(JSON.parse(JSON.stringify(page)))
                            delPages.push(pageIndex)
                            console.log('ppage', JSON.parse(JSON.stringify(page)))
                          } else {
                            let step = parseInt(count)
                            if (index) {
                              step = parseInt(index) + parseInt(tnumber)
                              count++
                            }
                            if (index > 0 || tnumber > 0) pageNumber++
                            const newPage = {
                              pagenumber: parseInt(pageNumber),
                              pagetitle: page.pagetitle + '-' + step,
                              flgrightsadvanced: page.flgrightsadvanced,
                              helptext: page.helptext,
                              pagepattern: JSON.parse(JSON.stringify(page.pagepattern)),
                              reportid: page.reportid,
                              template: page.template,
                              title: page.title,
                            }
                            newPage.pagepattern.data.forEach((x) => {
                              if (x.mode === 'table' && x.tableData.info.id === ttable.info.id) {
                                x.tableData = ttable
                              }
                            })
                            rect.tableData.info = resp.info
                            console.log('parseInt(page.pagenumber)', parseInt(pageNumber))
                            newPages.push(JSON.parse(JSON.stringify(newPage)))
                            // currentPage = this.pages[page.pagenumber + 1]
                          }
                        })
                      } else {
                        if (rect.maxTables && temptableList.length > 2) {
                          if ((tnumber + 1) > rect.maxTables && (tnumber % rect.maxTables === 0)) {
                            pageNumber++
                            const newPage = {
                              pagenumber: pageNumber,
                              pagetitle: page.pagetitle + '-' + (tnumber % rect.maxTables + 1),
                              flgrightsadvanced: page.flgrightsadvanced,
                              helptext: page.helptext,
                              pagepattern: JSON.parse(JSON.stringify(page.pagepattern)),
                              reportid: page.reportid,
                              template: page.template,
                              title: page.title,
                            }
                            newPage.pagepattern.data = JSON.parse(JSON.stringify(pagePatData))
                            newPage.pagepattern.data.forEach((x) => {
                              if (x.mode === 'table' && x.tableData.info.id === resp.info.id) {
                                x.tableData = resp
                              }
                            })
                            currentpage = JSON.parse(JSON.stringify(newPage))
                          } else if (!currentpage) {
                            currentpage = JSON.parse(JSON.stringify(page))
                          }
                          const tempRect = JSON.parse(JSON.stringify(rect))
                          tempRect.top = originTableTop + (oneTableHeight * (tnumber % rect.maxTables))
                          tempRect.height = oneTableHeight
                          tempRect.tableData.info = resp.info
                          tempRect.tableData.data = resp.data
                          tempRect.tableData.col = resp.col
                          tempRect.tableData.row = resp.row
                          if (tnumber % rect.maxTables === 0) {
                            currentpage.pagepattern.data.splice(rectIndex, 1, tempRect)
                          } else {
                            currentpage.pagepattern.data.push(tempRect)
                          }
                          if (tnumber === 0) delPages.push(pageIndex)
                          if (((tnumber + 1) % rect.maxTables === 0 && temptableList.length - 1 >= indx) || ((tnumber + 1) % rect.maxTables !== 0 && temptableList.length - 1 === indx)) {
                            newPages.push(JSON.parse(JSON.stringify(currentpage)))
                          }
                        } else if (tnumber > 0) {
                          pageNumber++
                          const newPage = {
                            pagenumber: pageNumber,
                            pagetitle: page.pagetitle + '-' + tnumber,
                            flgrightsadvanced: page.flgrightsadvanced,
                            helptext: page.helptext,
                            pagepattern: JSON.parse(JSON.stringify(page.pagepattern)),
                            reportid: page.reportid,
                            template: page.template,
                            title: page.title,
                          }
                          newPage.pagepattern.data.forEach((x) => {
                            if (x.mode === 'table' && x.tableData.info.id === resp.info.id) {
                              x.tableData = resp
                            }
                          })
                          rect.tableData.info = resp.info
                          rect.tableData.info = resp.info
                          rect.tableData.data = resp.data
                          rect.tableData.col = resp.col
                          rect.tableData.row = resp.row
                          newPages.push(newPage)
                          delPages.push(pageIndex)
                        } else {
                          rect.tableData.info = resp.info
                          rect.tableData.data = resp.data
                          rect.tableData.col = resp.col
                          rect.tableData.row = resp.row
                        }
                      }
                      tnumber++
                      count++
                    } else if (temptableList.length === 1) {
                      hidePages.push(pageIndex)
                    }
                  })
                }
              })
            })
            delPages.reverse().forEach(pindex => this.pages.splice(pindex, 1))
            this.pages = this.pages.concat(newPages)
            this.pages.sort((a, b) => {
              return a.pagenumber - b.pagenumber
            })
            if (hidePages.length > 0) {
              hidePages.sort()
              hidePages.reverse()
              hidePages.forEach((pindex) => {
                this.pages.splice(pindex, 1)
              })
              this.pages.forEach((page, pindex) => {
                page.pagenumber = pindex + 1
              })
            }
            this.$store.dispatch('setDataLoading', false)
            this.showAll = true
            console.log('-=-=-=-END-=-=-=-')
            this.dataFullLoading = true
          }).catch((e) => {
            this.$store.dispatch('setDataLoading', false)
          })
        })
      },
      zoomIn () {
        this.scale = this.scale + this.zoomStep
      },
      zoomOut () {
        this.scale = this.scale - this.zoomStep
      },
      myMethod (evnt) {
        if (evnt.code === 'Backspace' && this.activeArr.length > 0) {
          if (this.activeArr.length > 1) {
            this.activeArr.sort((a, b) => {
              return b - a
            })
            this.activeArr.forEach((x) => {
              this.selected.pagepattern.data.splice(x, 1)
            })
          } else {
            this.selected.pagepattern.data.splice(this.activeArr[0], 1)
          }
          this.activeArr = []
        }
      },
      activethrow (index) {
        const activeIndex = this.activeArr.findIndex(x => x === index)
        this.activeArr.splice(activeIndex, 1)
      },
      addactive (index) {
        this.activeArr.push(index)
      },
      getTableTemplate () {
        this.$store.dispatch('setDataLoading', true)
        this.$store.dispatch('getTableTemplate', this.templateId).then((res) => {
          this.selected.pagepattern.data.push({
            maxRows: null,
            width: 200,
            height: 150,
            top: 150,
            left: 150,
            draggable: true,
            resizable: true,
            minw: 10,
            minh: 10,
            axis: 'both',
            parentLim: true,
            snapToGrid: false,
            aspectRatio: false,
            zIndex: 1,
            color: '#EF9A9A',
            active: false,
            mode: this.rectMode,
            fontSize: 35,
            tableData: res,
          })
          this.$store.dispatch('setDataLoading', false)
          this.addTableDialog = false
        }).catch((e) => {
          console.log('Error: ', e)
          this.selected.modified = true
          this.$store.dispatch('setDataLoading', false)
          this.addTableDialog = false
        })
      },
      genSequence (from, to) {
        const numbers = []
        for (let i = from; i <= to; i++) {
          numbers.push(i)
        }
        return numbers
      },
      textStyle (rect) {
        let addStyle = ''
        if (rect.textStyle) {
          for (const key in rect.textStyle) {
            if (rect.textStyle[key]) {
              if (['color', 'backgroundColor'].includes(key)) {
                addStyle = `${addStyle} ${key.replace(/[A-Z]/g, '-$&').toLowerCase()}: ${rect.textStyle[key].hex};`
              } else if (key === 'borderColor') {
                addStyle = `${addStyle} border: 3px solid ${rect.textStyle[key].hex};`
              } else if (Number.isInteger(rect.textStyle[key])) {
                addStyle = `${addStyle} ${key.replace(/[A-Z]/g, '-$&').toLowerCase()}: ${rect.textStyle[key]}px;`
              } else {
                addStyle = `${addStyle} ${key.replace(/[A-Z]/g, '-$&').toLowerCase()}: ${rect.textStyle[key]};`
              }
            }
          }
        }
        return addStyle
      },
      editText (rect) {
        this.rectInput = {}
        this.rectInput = rect
        this.editTextDialog = true
      },
      choosePage (pageNumb) {
        this.selected = null
        this.$nextTick(() => {
          this.selected = this.pages[pageNumb]
        })
      },
      flipPage (direction) {
        if (!this.selected) {
          this.selected = this.pages.find(x => x.pagenumber === 0)
        } else {
          if (direction < 0 && this.selected.pagenumber === 0) return null
          if (direction > 0 && this.selected.pagenumber === this.pages.length - 1) return null
          this.selected = this.pages.find(x => x.pagenumber === this.selected.pagenumber + direction)
        }
        return null
      },
      slideMode (page) {
        fullscreen.toggle(this.$el.querySelector('#page-layout'), {
          teleport: false,
          callback: (isFullscreen) => {
            this.editable = !isFullscreen
            this.fullscreen = isFullscreen
          },
        })
      },
      changePosition (newRect, index) {
        this.selected.pagepattern.data[index].top = newRect.top
        this.selected.pagepattern.data[index].left = newRect.left
        this.selected.pagepattern.data[index].width = newRect.width
        this.selected.pagepattern.data[index].height = newRect.height
      },
      changeSize (newRect, index) {
        this.selected.pagepattern.data[index].top = newRect.top
        this.selected.pagepattern.data[index].left = newRect.left
        this.selected.pagepattern.data[index].width = newRect.width
        this.selected.pagepattern.data[index].height = newRect.height
      },
      getObjectList () {
        console.log('emit')
      },
      updateTableData () {
        console.log('emit')
      },
      resize (newRect) {
        this.width = newRect.width
        this.height = newRect.height
        this.top = newRect.top
        this.left = newRect.left
      },
      startDrag (evt) {
        this.dragged = true
        console.log('startDrag offset', evt)
        this.mouseOffsetX = evt.offsetX
        this.mouseOffsetY = evt.offsetY
      },
      onResize () {
        this.windowHeight = window.innerHeight
        const addMinus = this.fullscreen ? 40 : 75 + 40
        this.scale = (this.windowHeight - addMinus) / this.plheight * 100 / 100
      },
    },
  }
</script>

<style lang="scss">
@import '../../../assets/dx.llight.css';

body::-webkit-scrollbar {
  display: none;
}

#gridContainer {
  height: 440px;
}
.sss > .v-overlay__content {
  width: 100%;
  height: 100%;
}
td {
  padding: 0px!important;
}
.v-tabs .v-tabs-bar .v-tab.v-tab--active.active-tab {
  color: #3c4858;
}

.version-label {
  position: absolute;
  z-index: 1010;
  right: 20px;
  top: -12px;
  height: 25px;
  border-radius: 10px;
  background-color: #e5e5e5;
}
.page-inner {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #2d2828;
  -webkit-box-shadow: 0 1px 12px black;
  box-shadow: 0 1px 12px black;
  z-index: 1;
}
.page-layout {
  /*margin: 20px;*/
  overflow: hidden;
  height: 100%;
  position: relative;
  background-color: #ffffff;
}
.present-page-title {
  position: absolute;
  left: 10px;
  top: 6px;
  color: #3a0909;
  background-color: rgba(229, 229, 229, 0.72);
  padding: 6px;
  border-radius: 6px;
}
.page-inner-layout {
  /*top: 80px;*/
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
.page-layout-cont-inner {
  display: flex;
  justify-content: center;
  margin: auto;
  width: 100%;
  height: 100%;
  position: relative;
  align-content: flex-start;
}
.page-layout-cont {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #959595;
}
.drag-zone-cont {
  width: 100%;
  height: 100%;
  position: relative;
}
.sidebar-button {
  margin: 12px;
  width: 50px;
  background-color: #00cae3;
}
.pages-side-bar {
  position: relative;
  height: 100%;
  width: 240px;
  background-color: #2d2828;
  box-shadow: 0 1px 12px black;
  z-index: 1;
}
.action-side-bar {
  position: relative;
  right: 0;
  height: 100%;
  width: 100px;
  background-color: #ffffff;
  box-shadow: 0 -1px 10px #626262;
}
.action-side-bar-inner {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-top: 20px;
  align-content: flex-start;
  justify-content: space-around;
}
.pages-side-bar-inner {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-around;
}
.page-mockup {
  background-color: #fafafa;
  position: relative;
  width:  155px;
  height: 110px;
  margin: 10px;
  padding: 20px;
  cursor: pointer;
}
.del-page {
  cursor: pointer;
  position: absolute;
  top: 2px;
  right: -10px;
  text-align: center;
  font-size: small;
  color: #FFFFFF;
  width: 20px;
  border-radius: 20px;
  height: 20px;
  background-color: #f97900;
}
.down-page {
  cursor: pointer;
  position: absolute;
  top: 52px;
  right: -10px;
  text-align: center;
  font-size: small;
  color: #00ffff;
  width: 20px;
  border-radius: 4px;
  height: 20px;
  background-color: #00b2f9;
}
.edit-page {
  cursor: pointer;
  position: absolute;
  top: 96px;
  right: -10px;
  text-align: center;
  font-size: small;
  color: #00ffff;
  width: 20px;
  border-radius: 9px;
  height: 20px;
  background-color: #7eb230;
}
.up-page {
  cursor: pointer;
  position: absolute;
  top: 28px;
  right: -10px;
  text-align: center;
  font-size: small;
  color: #00ffff;
  width: 20px;
  border-radius: 4px;
  height: 20px;
  background-color: #00b2f9;
}
.addition-info {
  all: initial;
  position: fixed;
  display: block;
  top: 5px;
  right: 5px;
  background-color: rgba(0, 202, 227, 0.86);
  width: 260px;
  height: fit-content;
  z-index: 2000;
  word-break: break-word;
  white-space: break-spaces;
  padding: 6px;
  margin-bottom: 0;
}

.beta-labl {
  position: absolute;
  left: -15px;
  top: -5px;
  height: 25px;
  background-color: #c3ffc3;
  border-radius: 10px;
  opacity: 0.5;
}
.tbi-none {
  position: relative;
  padding-top: 40px;
}
.header-fixed {
  position: absolute;
  top: 0;
  right: 0;
  width: calc(100% + 24px);
  z-index: 1;
}
.table-copy {
  clear: both;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  display: none;
}
.last-modified {
  box-shadow: inset 0px 0px 0px 2px black;
}
.table-wrapper {
.header-fixed {
  background: white;
  position: fixed;
  top: 110px;
  left: 88px;
  z-index: 2;
  width: calc(100% - 97px);
  height: 50px;
.text-right {
  padding-top: 0;
  padding-bottom: 0;
}
}
.table-copy {
  display: table;
  position: fixed;
  top: 148px;
  z-index: 1;
td {
  border: 1px solid black;
}
.collapse-col {
  background-color: white;
  border: transparent;
  border-top: 1px solid white;
}
}
}
.append-el {
td {
//.header-cell{
  //  width: 100%;
  //}
}
}
td.collapse-col div {
  background: none;
}
.cell-border tbody tr td.collapse-col {
  border: none!important;
}
.collapse-row {
  cursor: pointer;
  text-align: left!important;
  margin: 0px;
  height: 14px;
  width: 14px;
  border: 1px solid #3c4858
}
.triangle div {
  overflow: hidden;
  position: inherit;
}
.triangle div::after {
  content: ' ';
  position: absolute;
  top: -2px;
  left: -45px;
  width: 40px;
  height: 20px;
  -webkit-transform: rotate(45deg) translate(-3px, -28px);
  background: #ff9797;
}
/*.triangle div::after {*/
/*  content: '☉';*/
/*  position: absolute;*/
/*  top: -2px;*/
/*  left: 0;*/
/*  !*width: 40px;*!*/
/*  height: 0px;*/
/*  color: red;*/
/*  font-size: 13px;*/
/*  !*-webkit-transform: rotate(45deg) translate(0px, -25px);*!*/
/*  !*background: green;*!*/
/*}*/
.vcentred {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
}
.hidden-row  td:after {
  position: absolute;
  z-index: 1;
  text-align: center;
  left: 0;
  top: 0;
  width: 252%;
  height: .2em;
  content:"";
  background-color: rgba(255, 0, 0, .7);
}
.v-middle {
  /*position: absolute!important;*/
  /*top: 50%;*/
  /*transform: translateY(-50%);*/
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.tbi-none {
  outline:none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*table tr td.chosen div{*/
/*  !*border: 2px solid #4896da!important;*!*/
/*  box-shadow: inset 1px 1px 0px 1px #00b2f9;*/
/*}*/

table tr td.chosen-in:not(.chosen) {
  border: 1px solid #c9c9c9 !important;
  /*border: 1px solid #858585#c9c9c9 !important;*/
  /*box-shadow: inset 1px 1px 0px 1px #00b2f9;*/
}

table tr td.chosen-left:not(.chosen) {
  border-left: 1px double #00b2f9!important;
  /*box-shadow: inset 1px 1px 0px 1px #00b2f9;*/
}
/*table tr td.chosen-left:not(.chosen) div{*/
/*  border-left: 1px solid #00b2f9!important;*/
/*  !*box-shadow: inset 1px 1px 0px 1px #00b2f9;*!*/
/*}*/
table tr td.chosen-top:not(.chosen) {
  border-top: 1px double #00b2f9!important;
  /*box-shadow: inset 1px 1px 0px 1px #00b2f9;*/
}
/*table tr td.chosen-top:not(.chosen) div{*/
/*  border-top: 1px solid #00b2f9!important;*/
/*  !*box-shadow: inset 1px 1px 0px 1px #00b2f9;*!*/
/*}*/
table tr td.chosen-right:not(.chosen) {
  border-right: 1px solid #00b2f9!important;
}
/*table tr td.chosen-right:not(.chosen) div {*/
/*  border-right: 1px solid #00b2f9!important;*/
/*}*/
table tr td.chosen-bottom:not(.chosen) {
  border-bottom: 1px solid #00b2f9!important;
}

/*table tr td.chosen-bottom.chosen-right:not(.chosen):after {*/
/*  position: absolute;*/
/*  bottom: 0px;*/
/*  right: 0px;*/
/*  content: ' ';*/
/*  width: 5px;*/
/*  height: 5px;*/
/*  background-color: #00b2f9;*/
/*}*/
/*table tr td.chosen-bottom:not(.chosen) div{*/
/*  border-bottom: 1px solid #00b2f9!important;*/
/*}*/
table tr td.chosen {
  border: 1px double #4793d5 !important;
}
table tr td.chosen div{
  box-shadow: inset 0px 0px 0px 1px #4793d5 !important;
}

/*table tr td.cell {*/
/*  box-shadow: inset 0px 0px 0px 1px #4793d5 !important;*/
/*}*/

table tr td.cell {
  border: none!important;
  padding: 0;
  margin: 0;
}
table tr td.cell input:focus {
  border: 0px solid #4793d5 !important;
  background: none;
}

.chosenRow:before {
  content: "";
  position: absolute;
  left: 0; right: 0;
  top: 0; bottom: 0;
  background: rgba(0,0,0,.05);
}
.canEdit {
  cursor: pointer;
}
.cell-input {
  width: 100%;
  height: 100%;
  padding-left: 4px;
}
.withoutbg {
  background: none;
}

.select-border {
  background-color: #2d6da3;
}
.header-cell {
  width: 100%;
  height: 100%;
}
table.cell-border {
  clear: both;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
}

.cell-border th {
  padding: 0!important;
}
/*.cell-border thead tr th:first-child, .cell-border tbody tr td:first-child {*/
/*  border-left: 1px solid rgba(0, 0, 0, 1);*/
/*}*/
/*.cell-border thead tr th {*/
/*  padding: 0!important;*/
/*}*/
.cell-border tr th, .cell-border tr td:not(.chosen) {
  border: 1px solid black!important;
  padding: 0!important;
  overflow: hidden;
}
/*.cell-border tbody td {*/
/*  border-top: 1px solid rgba(0, 0, 0, 1);*/
/*  border-right: 1px solid rgba(0, 0, 0, 1);*/
/*}*/

/*.list .row {*/
/*  display: flex;*/
/*}*/

revogr-viewport-scroll.col {
  padding: 0;
}
.checkit{
  background-color: salmon;
}
.row.fit {
  display: block;
  margin: 0;
  text-align: center;
}

.custom-list-group:hover .v-list-group__header {
  background-color: #f6f6f6;
}

.custom-list-group {
.v-list-group__items {
  display: none;
.theme--light {
  display: flex;
  flex-direction: column;
  align-self: start;
  padding: 0;
&:after {
   display: none;
 }
.theme--light {
  padding: 0 16px;
  width: 100%;
  height: 20px;
&:hover {
   background-color: #f6f6f6;
 }
}
.v-list-item {
  min-height: 25px;
}
.v-list-item__title {
  display: flex;
  align-items: center;
  align-self: start;
}
.v-list-item--link {
&:after {
   height: 48px;
   display: none;
 }
&:before {
   height: 48px;
   display: none;
 }
}
}
}
}

.custom-list-group:hover .v-list-group__items {
  display: block;
}
.auto-sum-disable {
  display: none;
}

</style>
